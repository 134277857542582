// 【system】【地区】 管理

import { request, noTimeOutReq } from '@/utils/request.js'

// 新增某个地区，需要上级parentId,一级地区parentId为0
export function areaAdd (data) {
    return request({
        url: '/sys/org/area/add',
        method: 'post',
        data
    })
}

// 展示子地区
export function areaList (sysOrgAreaId, pageIndex, pageSize) {
    return request({
        url: `/sys/org/area/children/${sysOrgAreaId}/page/${pageIndex}/${pageSize}/list`,
        method: 'post',
    })
}

// 删除某个地区及其子地区,慎用
export function areaDelete (data) {
    return request({
        url: '/sys/org/area/delete',
        method: 'post',
        data
    })
}

// 展示一级地区
export function areaListFirst (pageIndex, pageSize) {
    return request({
        url: `/sys/org/area/page/${pageIndex}/${pageSize}/list`,
        method: 'post',
    })
}

// 修改某个地区
export function areaUpdate (data) {
    return request({
        url: '/sys/org/area/update',
        method: 'post',
        data
    })
}

// 展示某个地区及其所有子地区
export function areaListGet (sysOrgAreaId) {
    return request({
        url: `/sys/org/area/${sysOrgAreaId}/get`,
        method: 'post',
    })
}

//获取最高级ID（orgId）
export function getHighestArea () {
    return request({
        url: `/sys/org/area/root/get`,
        method: 'post',
    })
}