<template>
  <div>
    <el-row>
      <!-- 树形 -->
      <el-col :span="6">
        <div class="treeBox">
          <el-input placeholder="请输入关键词" v-model="filterText">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <div class="treeList">
            <el-tree
              class="filter-tree"
              :data="data"
              :props="defaultProps"
              :filter-node-method="filterNode"
              node-key="code"
              :default-expanded-keys="data"
              ref="tree"
              @node-click="handleNodeClick"
              :default-expand-all="true"
            ></el-tree>
          </div>
        </div>
      </el-col>
      <!-- 右侧管理 -->
      <el-col :span="18">
        <div class="setBox">
          <div>
            <el-input v-model="inputName" disabled>
              <template slot="prepend">名称</template>
            </el-input>
          </div>
          <div>
            <el-input v-model="inputId" disabled>
              <template slot="prepend">机构编码</template>
            </el-input>
          </div>
          <div>
            <el-input v-model="inputLevel" disabled>
              <template slot="prepend">层级</template>
            </el-input>
          </div>
          <div v-if="bol">
            <el-input v-model="inputArea" disabled>
              <template slot="prepend">所属区域</template>
            </el-input>
          </div>
          <el-button
            v-throttle
            type="primary"
            size="medium"
            @click="changeSubOrg"
            >修改</el-button
          >
          <el-button v-throttle type="danger" size="medium" @click="delSubOrg"
            >删除</el-button
          >
          <el-button
            v-throttle
            type="primary"
            size="medium"
            @click="addSubOrg"
            v-show="subOrgBtn"
            >新增子机构</el-button
          >
          <!-- <div class="setBoxLine"></div>
          <el-button v-throttle  type="primary" size="medium">广老大</el-button> -->
        </div>
      </el-col>
    </el-row>

    <!-- 弹框 -->
    <!-- 新增机构 -->
    <el-dialog
      class="addMechanism"
      title="新增子机构"
      :visible.sync="dialogVisibleAdd"
      width="30%"
      v-if="dialogVisibleAdd"
    >
      <el-form
        :model="addAreaInfo"
        :rules="rules"
        ref="addAreaInfo"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="addAll">
          <el-form-item label="名称" prop="addAreaName">
            <el-input
              v-model="addAreaInfo.addAreaName"
              maxlength="12"
              placeholder
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="机构编码" prop="addAreaId">
            <el-input
              v-model="addAreaInfo.addAreaId"
              maxlength="16"
              placeholder
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="层级">
            <el-input
              v-model="addAreaInfo.addHierarchy"
              disabled
              placeholder
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="经度">
            <el-input
              v-model="addAreaInfo.longitude"
              placeholder
              size="small"
              maxlength="10"
              oninput="value=value.replace(/[^\d.]/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item label="纬度">
            <el-input
              v-model="addAreaInfo.latitude"
              placeholder
              size="small"
              maxlength="10"
              oninput="value=value.replace(/[^\d.]/g,'')"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button
            v-throttle
            type="primary"
            size="medium"
            @click="preAddSubOrg('addAreaInfo')"
            >保 存</el-button
          >
          <el-button v-throttle size="medium" @click="dialogVisibleAdd = false"
            >取 消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog
      title="修改"
      :visible.sync="dialogVisibleRevise"
      width="30%"
      v-if="dialogVisibleRevise"
    >
      <el-form
        :model="editAreaInfo"
        :rules="rules"
        ref="editAreaInfo"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="addAll">
          <el-form-item label="名称" prop="editAreaName">
            <el-input
              v-model="editAreaInfo.editAreaName"
              maxlength="12"
              placeholder
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="机构编码" prop="editAreaId">
            <el-input
              v-model="editAreaInfo.editAreaId"
              maxlength="16"
              placeholder
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="层级">
            <el-input
              v-model="editAreaInfo.eidtHierarchy"
              disabled
              placeholder
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="经度">
            <el-input
              v-model="editAreaInfo.longitude"
              placeholder
              size="small"
              maxlength="10"
              oninput="value=value.replace(/[^\d.]/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item label="纬度">
            <el-input
              v-model="editAreaInfo.latitude"
              placeholder
              size="small"
              maxlength="10"
              oninput="value=value.replace(/[^\d.]/g,'')"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button
            v-throttle
            type="primary"
            @click="preSubOrg('editAreaInfo')"
            size="medium"
            >保 存</el-button
          >
          <el-button
            v-throttle
            size="medium"
            @click="dialogVisibleRevise = false"
            >取 消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  areaListGet,
  areaUpdate,
  areaAdd,
  areaDelete,
  getHighestArea,
} from '@/api/mechanism/systemArea.js'
export default {
  // 机构管理
  name: 'mechanismManage',
  data() {
    return {
      rules: {
        editAreaName: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        editAreaId: [
          { required: true, message: '请输入机构编码', trigger: 'blur' },
        ],
        addAreaName: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        addAreaId: [
          { required: true, message: '请输入机构编码', trigger: 'blur' },
        ],
      },
      //右侧input
      inputName: '广东省',
      inputId: '440000',
      inputLevel: '省级',
      inputArea: '广东省',
      //中间变量
      //经度
      prelng: '',
      //纬度
      prelat: '',
      //父级ID
      parentId: '',
      //主键ID
      sysOrgId: '',

      //显示状态
      bol: false,
      subOrgBtn: true,
      addName: '',
      filterText: '',
      data: [],
      defaultProps: {
        children: 'childrenOrgArea',
        label: 'name',
      },
      dialogVisibleAdd: false,
      dialogVisibleRevise: false,
      //修改弹窗
      editAreaInfo: {
        editAreaName: '',
        editAreaId: '',
        eidtHierarchy: '',
        longitude: '',
        latitude: '',
      },
      //新增弹窗
      addAreaInfo: {
        addAreaName: '',
        addAreaId: '',
        addHierarchy: '',
        longitude: '',
        latitude: '',
      },
      //最高级地区ID
      highestId: 0,
    }
  },

  mounted() {
    this.getHighestId()
  },

  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  methods: {
    //   过滤
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },

    //展开所有节点
    // setAllExpand () {
    //   for (var i = 0; i < this.$refs.tree.store._getAllNodes().length; i++) {
    //     this.$refs.tree.store._getAllNodes()[i].expanded = true;
    //   }
    // },

    //点击左侧地区菜单栏右侧菜单动态变化
    handleNodeClick(data) {
      console.log(data)
      this.inputName = data.name
      this.inputId = data.code
      //经纬度
      this.prelat = data.lat
      this.prelng = data.lng
      //父级ID和主键ID
      this.parentId = data.parentId
      this.sysOrgId = data.sysOrgAreaId
      if (data.depth == 2) {
        this.bol = true
        this.inputLevel = '区县级'
        this.subOrgBtn = false
      } else if (data.depth == 1) {
        this.bol = false
        this.inputLevel = '市级'
        this.inputArea = data.name
        this.subOrgBtn = true
      } else {
        this.inputLevel = '省级'
        this.bol = false
        this.subOrgBtn = true
      }
    },

    //初始拿到最高级的ID
    getHighestId() {
      getHighestArea().then((res) => {
        let { code, data } = res
        if (code == 200) {
          this.highestId = data.orgId
          this.resTree()
        }
      })
    },

    //左侧地区菜单栏展示数据
    async resTree() {
      this.data = []
      areaListGet(this.highestId).then((res) => {
        let { code, data } = res
        if (code == 200) {
          this.data.push(data)
          this.handleNodeClick(data)
        } else {
        }
      })
    },

    //新增子机构-打开弹窗
    addSubOrg() {
      this.dialogVisibleAdd = true
      if (this.inputLevel == '省级') {
        this.addAreaInfo.addHierarchy = '市级'
      } else {
        this.addAreaInfo.addHierarchy = '区级'
        this.addAreaInfo.addAreaName = ''
        this.addAreaInfo.addAreaId = ''
        this.addAreaInfo.longitude = ''
        this.addAreaInfo.latitude = ''
      }
    },

    //新增子机构-保存按钮
    preAddSubOrg(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let addSubOrgParam = {
            name: this.addAreaInfo.addAreaName,
            code: this.addAreaInfo.addAreaId,
            parentId: this.sysOrgId,
            lng: this.addAreaInfo.longitude,
            lat: this.addAreaInfo.latitude,
          }
          areaAdd(addSubOrgParam).then((res) => {
            let { code, data, msg } = res
            if (code == 200) {
              this.$message.success('新增子机构成功！')
              this.resTree()
              this.dialogVisibleAdd = false
            } else {
              this.$message.error(msg)
            }
          })
        } else {
        }
      })
    },

    //修改子机构-打开弹窗
    changeSubOrg() {
      this.dialogVisibleRevise = true
      // this.region = this.inputName
      this.editAreaInfo.editAreaName = this.inputName
      this.editAreaInfo.editAreaId = this.inputId
      this.editAreaInfo.eidtHierarchy = this.inputLevel
      this.editAreaInfo.longitude = this.prelng
      this.editAreaInfo.latitude = this.prelat
    },

    //修改子机构-保存按钮
    preSubOrg(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let SubOrgParam = {
            name: this.editAreaInfo.editAreaName,
            code: this.editAreaInfo.editAreaId,
            parentId: this.parentId,
            sysOrgAreaId: this.sysOrgId,
            lng: this.editAreaInfo.longitude,
            lat: this.editAreaInfo.latitude,
          }
          console.log(SubOrgParam)
          areaUpdate(SubOrgParam).then((res) => {
            let { code, data, msg } = res
            if (code == 200) {
              console.log('PREres', res)
              this.$message.success('修改地区成功！')
              this.dialogVisibleRevise = false
              this.resTree()
            } else {
              this.$message.error(msg)
            }
          })
        } else {
        }
      })
    },

    //删除此机构
    delSubOrg() {
      this.$confirm(`确定删除${this.inputName}吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const delAreaList = []
          delAreaList.push(this.sysOrgId)
          areaDelete(delAreaList).then((res) => {
            let { success, data, msg } = res
            if (success) {
              this.resTree()
              this.$message({
                type: 'success',
                message: `删除${this.inputName}成功！`,
              })
            } else {
              this.$message.error(msg)
            }
          })
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.treeBox {
  height: calc(100vh - 108px);
  overflow: hidden;
  box-sizing: border-box;
  padding-right: 20px;
  border-right: 1px solid #ccc;
}

.treeList {
  height: calc(100% - 40px);
  overflow-y: auto;
}

.setBox {
  padding-left: 20px;

  > div {
    width: 50%;
    margin-bottom: 20px;
  }

  .setBoxLine {
    width: 100%;
    position: relative;
    height: 60px;
    margin: 0;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #000;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 2;
    }

    &::before {
      content: '区域管理员';
      line-height: 60px;
      padding: 0 10px;
      z-index: 3;
      display: inline-block;
      background-color: #fff;
      position: absolute;
      left: 10%;
    }
  }
}

.addMechanism {
  .addMain {
    margin-left: 30px;

    .spanText {
      display: inline-block;
      text-align: right;
      width: 70px;
    }

    div {
      margin-left: 0px;

      &:nth-child(2) {
        margin-top: 20px;
      }
    }

    .el-input {
      width: 50%;
    }
  }
}

::v-deep .el-input-group__prepend {
  width: 56px;
  text-align: center;
}

.addAll {
  .el-input {
    width: 85%;
    margin-left: 15px;
  }
}
</style>
